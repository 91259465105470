<template>
  <div class="billingview offer" id="cost_statement">
    <!-- 报价操作框 -->
    <div class="billingview_headoperate">
      <!-- 标题 -->
      <div class="head_operateList">
        <ul class="list">
          <li>
            <span>计划完工日期</span>
          </li>
          <li>
            <el-row>
              <el-col :span="10">
                <el-date-picker type="date" placeholder="选择日期" size="mini" v-model="startTime" style="width: 100%;">
                </el-date-picker>
              </el-col>
              <el-col class="line" :span="3" :push="1"> - </el-col>
              <el-col :span="10">
                <el-date-picker type="date" placeholder="选择日期" size="mini" v-model="endTime" style="width: 100%;">
                </el-date-picker>
              </el-col>
            </el-row>
          </li>
          <li>
            <el-input placeholder="生产单号" size="mini" v-model="mesCode"></el-input>
          </li>
          <li>
            <el-input placeholder="货品编号/名称/规格" size="mini" v-model="otherCode"></el-input>
          </li>
          <li>
            <el-button type="primary" size="mini" @click="initcostListData()">查询</el-button>
          </li>
          <li>
            <el-button type="success" size="mini" :disabled="calculateButton!=2" @click="calculate()">{{calculateButton==0?"待处理":calculateButton==1?"处理中":calculateButton==2?"计算":''}}</el-button>
          </li>
        </ul>
      </div>
    </div>
    <div class="billingview_tablewrapper billing_table">
      <el-table ref="costTableList" :data="costListData" @select-all="getSelectedAllData" @select="getSelectedOfferData"
        row-key="id" border show-summary height="100%" style="width: 100%" v-if="show_again_load_table">
        <!-- 索引 -->
        <el-table-column type="index" fixed width="35"></el-table-column>
        <!-- 全选 -->
        <el-table-column type="selection" fixed width="30"></el-table-column>
        <el-table-column prop="bills_no" label="生产单号" width="180" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="span_pointer span_color_blue" :title="scope.row.bills_no"
               @click="updateBillMesData(scope.row,2)">{{scope.row.bills_no}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="complete_date" label="完工时间" width="180" show-overflow-tooltip></el-table-column>
        <el-table-column prop="product_code" label="货品编号" width="180" show-overflow-tooltip></el-table-column>
        <el-table-column prop="name" label="货品名称" width="180" show-overflow-tooltip></el-table-column>
        <el-table-column prop="specifications" label="规格型号" width="180" show-overflow-tooltip></el-table-column>
        <el-table-column prop="plan_num" label="计划生产数" width="100" show-overflow-tooltip></el-table-column>
        <el-table-column prop="success_number" label="成品入库数" width="100" show-overflow-tooltip></el-table-column>
        <el-table-column prop="budgetMaterial" label="预算物料" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column prop="actualMaterial" label="实际物料" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column prop="returnMaterial" label="退料" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column prop="supplementaryFood" label="补料" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column prop="scrapCost" label="报废" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column prop="materialCost" label="物料成本" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column prop="staffCost" label="人工" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column prop="administrativeCost" label="管理费" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column prop="fabricatingCost" label="制造费" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column prop="otherCost" label="其他费用" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column prop="expenseCost" label="报销费用" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column prop="totalCost" label="总计成本" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column prop="singleCost" label="单个成本" width="120" show-overflow-tooltip></el-table-column>
      </el-table>
    </div>
     <!-- 表格分页 -->
    <div class="billing_tablePage">
      <el-pagination @size-change="changePageSize" @current-change="changeCurrentPage"
        :current-page="pageData.currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageData.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="pageData.totalPage">
      </el-pagination>
    </div>

    <!-- 新增单据组件 -->
    <nodeBills :nodeTypeCode="nodeTypeCode" :billsClassify="billsClassify" :billsTypeSign="billsTypeSign"
      :updateDefaultData="updateDefaultData" :operateType="operateType" :generateBillsId="generateBillsId"
      :generateBillsSign="generateBillsSign" :generateBillsOriginData="generateBillsOriginData"
      :billsTypeDefaultCode="billsTypeDefaultCode" :isGenerateOperateType="isGenerateOperateType"
      :dictionariesDefaultData="dictionariesDefaultData" v-if="show_nodeBills"></nodeBills>
  </div>
</template>

<script>

   import api from '@/network/production/production.js'; //生产模块相关接口

   import nodeBills from '@/components/commComponent/billing/nodeBills'; //新建单据公共组件
  export default {
    name: 'cost_statement',

    data() {
      return {
        //开始时间
        startTime: '',
        //结束时间
        endTime: '',
        //生产单号
        mesCode: '',
        //货品编号 名称 规格
        otherCode: '',

        //分页数据
        pageData: {
          currentPage: 1, //当前页
          totalPage: 0, //总页数
          pageSize: 20 //页面大小
        },

        //传入子组件的数据
        nodeTypeCode: 'SYS-NODE-TYPE-001', //单据分类code
        operateType: 2, //操作类型(0:新增 1:修改  2:查看)
        billsTypeSign: 0, //单据分类标签(0代表销售)
        billsClassify: -1, //单据分类
        billsTypeDefaultCode: '', //默认单据类型
        isGenerateOperateType: -1, //是否为生成类型操作
        generateBillsId: -1, //需要生成其他单据的id
        generateBillsSign: -1, //源单分类标识
        generateBillsOriginData: {}, //需要生成其他单据的数据源
        dictionariesDefaultData: {}, //数据字典默认数据
        updateDefaultData: { //修改时默认流程和节点等信息
          nodeId: -1, //节点id
          processId: -1, //流程id
        },
        billType: '', //单据权期分类

        //控制开关
        show_nodeBills: false, //控制显示公共单据组件弹框

        //当前组件数据
        costListData: [],
        show_again_load_table: true, //是否重新加载表格组件(防止路由切换等导致界面错位)

        //计算按钮
        calculateButton:2,//(0:待处理 1:处理中 2:处理完成)
      }
    },
    mounted(){
      this.initcostListData();
    },
    methods: {

      //初始化数据
      initcostListData(){
        //查询公共参数
        let data = {
          pageIndex: this.pageData.currentPage, //当前页
          pageSize: this.pageData.pageSize, //页面大小
        }
        //判断是否有查询条件
        if(!!this.startTime){
          data.startTime=this.commonJsExtend.getDateTime(this.startTime,0)
        }
        if(!!this.endTime){
          data.endTime=this.commonJsExtend.getDateTime(this.endTime,0)
        }
        if(!!this.mesCode){
          data.mesCode=this.mesCode
        }
        if(!!this.otherCode){
          data.otherCode=this.otherCode
        }

        // 发送请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#cost_statement", 4, '数据更新中,请稍后...');
        api.queryingCostDetails(data).then(res=>{
          //关闭弹框
          this.loading.close();
          //验证请求后的结果
          if (res.code == 200) {
            //将数据查询到的数据存入data
            this.costListData = res.data.rows;
            //获取分页数据
            this.pageData.totalPage = res.data.total
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
        api.calculationStatus().then(res=>{
          //验证请求后的结果
          if (res.code == 200) {
            if(!!res.data){
              this.calculateButton=Number(res.data)
            }else{
              this.calculateButton=2
            }
            
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      //条件查询
      queryCondition() {

       },
      /*预览生产计划单*/
            updateBillMesData(data, type, operateOrigin, nodeTypeCode) {
              // console.log(data)
              //将操作设置为对应type
              this.operateType = type;
              //设置单据类型标签(设置为生产计划单模块)
              this.billsTypeSign = 1;
              //获取需要回显的单据id
              this.generateBillsId = data.id;
              //设置单据分类类型
              this.billsClassify = 0;
              //判断是否为预览界面的修改
              if (nodeTypeCode) {
                this.nodeTypeCode = nodeTypeCode;
              } else {
                //设置单据分类code为销售
                this.nodeTypeCode = 'SYS-NODE-TYPE-003';
              }
              //设置默认相关数据
              this.updateDefaultData = {
                nodeId: data.status_id, //节点id
                processId: data.process_id, //流程id
              }
              //设置默认单据类型
              if (!operateOrigin) {
                this.billsTypeDefaultCode = "DOC-M-001";
              }
              //显示操作弹框
              this.show_nodeBills = true;
            },

            /* 分页数据大小改变 */
            changePageSize(val) {
              //获取分页大小
              this.pageData.pageSize = val;
              //请求数据
              this.initcostListData()
            },

            /* 当前页改变 */
            changeCurrentPage(val) {
              //获取当前页
              this.pageData.currentPage = val;
              //请求数据
              this.initcostListData()
            },

            /* 全选列表数据 */
            getSelectedAllData(selection) {
              //获取数据
              this.offerSelData = [];
              if (selection.length != 0) {
                selection.forEach(item => {
                  this.offerSelData.push(item);
                })
              }
              // this.offerSelData = [];
              // //直接清除,不可全选
              // this.$refs.sellTableList.clearSelection();
            },
            /* 获取列表已选数据 */
            getSelectedOfferData(selection, row) {
              //获取单选数据
              this.offerSelData = [];
              if (selection.length != 0) {
                selection.forEach(item => {
                  this.offerSelData.push(item);
                })
              }
              //设置单选
              // this.$refs.sellTableList.clearSelection();
              // this.$refs.sellTableList.toggleRowSelection(row);
            },
            //计算弹框
            calculate() {
              api.costAccountingCalculation().then(res=>{
                //验证请求后的结果
                if (res.code == 200) {
                  this.calculateButton=0
                   this.$message({
                    type: 'success',
                    message: "开始处理计算程序，请稍后",
                    duration: this.elDuration
                  })
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
               let timer = setInterval(() => {
                 api.calculationStatus().then(res=>{
                   //验证请求后的结果
                   if (res.code == 200) {
                     console.log(res.data)
                     this.calculateButton=Number(res.data)
                     if(this.calculateButton==2){
                       clearInterval(timer)
                     }
                   } else {
                     this.$message({
                       type: 'error',
                       message: res.message,
                       duration: this.elDuration
                     })
                   }
                 })
                   },5000)
            },

            /* 关闭单据公共弹框 */
            closeNodeBills() {
              this.show_nodeBills = false;
            },

          },
    components: {
      nodeBills,
    },
    }


</script>

<style>
</style>
